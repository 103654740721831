.main {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85);
  transition: background-color 1s ease;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

#bgVideo {
  position: fixed;
  max-height: 100%;
  max-width: 100%;
  bottom: 0;
  z-index: -100;
}

.centered {
  font-size: 15vw;
  font-family: Typewriter;
}

@font-face {
  font-family: Typewriter;
  src: url("../public/typewriter.ttf") format("truetype");
}
